import { DEFAULT_LIMIT } from "@common/constant";
import { dayjsToISODate } from "@common/utils/date";
import Notification from "@domain/entities/notification-entity";
import {
  IGetNotificationDetail,
  IGetNotificationList,
  IGetNotificationScreenList,
  IQueryNotification,
  IRemoveNotificationBody
} from "@domain/interfaces/notification-interface";
import NotificationRepository from "@domain/repositories/notification-repository";
import ApiService from "@infrastructure/network/api";
import { injectable } from "inversify";

@injectable()
export default class NotificationApiRepository implements NotificationRepository {
  private apiService = new ApiService();

  async getList (query: IQueryNotification): Promise<IGetNotificationList> {
    const filter = { ...query };

    if (filter.created_at) filter.created_at = dayjsToISODate(filter.created_at);

    const result = await this.apiService.get("/notification/list", filter);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async createBySegment (body: Notification): Promise<IGetNotificationDetail> {
    const result = await this.apiService.post(`/notification/segment/${body.segment_id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (body: IRemoveNotificationBody): Promise<any> {
    const result = await this.apiService.remove("/notification", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async getScreenList (): Promise<IGetNotificationScreenList> {
    const result = await this.apiService.get("/notification/screens", {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }
}
