import Event from "@domain/entities/event-entity";
import EventRepository from "@domain/repositories/event-repository";
import ApiService from "@infrastructure/network/api";

import { DEFAULT_LIMIT } from "@common/constant";
import {
  IGetEventDetail,
  IGetEventList,
  IGetUserLuckyNumber,
  IGetUserLuckyNumberBody,
  IQueryEvent,
  ISentEventUserBody
} from "@domain/interfaces/event-interface";
import { injectable } from "inversify";

@injectable()
export default class EventApiRepository implements EventRepository {
  private api = new ApiService();

  async getList (query: IQueryEvent): Promise<IGetEventList> {
    const result = await this.api.get("/event", query);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: {
        items: result.data?.items || [],
        total: result.data?.total || 0,
        limit: result.data?.limit || DEFAULT_LIMIT,
      },
    };
  }

  async create (body: Event): Promise<IGetEventDetail> {
    const result = await this.api.post("/event", body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async update (id: string, body: Partial<Event>): Promise<IGetEventDetail> {
    const result = await this.api.put(`/event/${id}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async remove (id: string): Promise<IGetEventDetail> {
    const result = await this.api.remove(`/event/${id}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async getUserLuckyNumber (id: string, body: IGetUserLuckyNumberBody): Promise<IGetUserLuckyNumber> {
    const result = await this.api.put(`/event/${id}/lucky-number`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async sendInviteEvent (id: string, body: ISentEventUserBody): Promise<IGetEventDetail> {
    const result = await this.api.post(`/event/${id}/send-invite`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async removeInviteUser (id: string, userId: string): Promise<IGetEventDetail> {
    const result = await this.api.remove(`/event/${id}/user/${userId}`);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
