/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";
import ErrorCode from "@common/kernel/error-code";
import Event from "@domain/entities/event-entity";
import {
  IGetEventDetail,
  IGetEventList,
  IGetUserLuckyNumber,
  IGetUserLuckyNumberBody,
  IQueryEvent,
  ISentEventUserBody
} from "@domain/interfaces/event-interface";

export default abstract class EventRepository {
  async getList (query?: IQueryEvent): Promise<IGetEventList> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async create (data: Event): Promise<IGetEventDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async update (id: string, body: Partial<Event>): Promise<IGetEventDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async remove (id: string): Promise<IGetEventDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async getUserLuckyNumber (id: string, body: IGetUserLuckyNumberBody): Promise<IGetUserLuckyNumber> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async sendInviteEvent (id: string, body: ISentEventUserBody): Promise<IGetEventDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async removeInviteUser (id: string, userId: string): Promise<IGetEventDetail> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
